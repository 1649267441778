import React, { useState, useEffect, useRef, useCallback } from "react";

const VideoPlayer = ({ videoData, onVideoEnd }) => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [allVideosPlayed, setAllVideosPlayed] = useState(false);
  const videoRefs = useRef([]);
  const shouldLoop = videoData?.length === 1;

  const handlePlayError = (index) => {
    const currentVideo = videoRefs.current[index];
    if (currentVideo) {
      currentVideo.play().catch((error) => {
        console.log(
          `Errore nel tentativo di riprodurre il video 2: ${error.name}, ${error.message}`
        );
        console.log(error.stack);
        setTimeout(() => {
          currentVideo.play().catch((error) => {
            console.log(
              `Errore nel tentativo di riprodurre il video 3: ${error.name}, ${error.message}`
            );
            console.log(error.stack); // Se vuoi vedere lo stack trace separatamente
          });
        }, 1000);
      });
    }
  };

  // Gestione del video quando finisce
  const handleVideoEnd = useCallback(() => {
    if (currentVideoIndex === videoData?.length - 1) {
      setAllVideosPlayed(true);
    } else {
      setCurrentVideoIndex(currentVideoIndex + 1);
    }
  }, [currentVideoIndex, videoData]);

  // Riprodurre video al caricamento e gestire errori
  useEffect(() => {
    const currentVideo = videoRefs.current[currentVideoIndex];
    if (currentVideo) {
      currentVideo.addEventListener("ended", handleVideoEnd);
      currentVideo.play().catch((error) => {
        console.log("Errore durante la riproduzione, riprovo:", error);
        handlePlayError(currentVideoIndex); // Retry in caso di errore
      });
      console.log("video started, currentVideoIndex:", currentVideoIndex);
    }

    return () => {
      if (currentVideo) {
        currentVideo.removeEventListener("ended", handleVideoEnd);
        currentVideo.pause();
        console.log("video paused, currentVideoIndex:", currentVideoIndex);
      }
    };
  }, [currentVideoIndex, videoRefs, videoData]);

  // Riprendere la riproduzione se la finestra torna visibile
  /*   useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        const currentVideo = videoRefs.current[currentVideoIndex];
        if (currentVideo) {
          currentVideo.play().catch((error) => {
            console.log("Errore nel tentativo di riprodurre il video:", {
              name: error.name,
              message: error.message,
              stack: error.stack,
            });
          });
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [currentVideoIndex]); */

  // Quando tutti i video sono stati riprodotti
  useEffect(() => {
    if (allVideosPlayed) {
      setCurrentVideoIndex(0);
      setAllVideosPlayed(false);
      onVideoEnd(); // Callback per avvisare il parent
    }
  }, [allVideosPlayed, setCurrentVideoIndex, onVideoEnd]);

  // Precaricamento dei video
  useEffect(() => {
    if (videoData && videoData.every((row) => videoRefs.current[row.id])) {
      const loadVideos = async () => {
        await Promise.all(
          videoRefs.current.map((video) => {
            return new Promise((resolve) => {
              video.addEventListener("loadedmetadata", () => {
                resolve();
              });
            });
          })
        );
      };
      loadVideos();
    }
  }, [videoData, videoRefs]);

  return (
    <>
      {videoData &&
        videoData.map((item, index) => (
          <video
            key={index}
            ref={(element) => (videoRefs.current[index] = element)}
            src={`https://backendstage.monreale.bbsitalia.com${item.video}`}
            autoPlay={index === 0}
            muted
            playsInline
            className={
              "w-full h-full object-cover absolute top-0 left-0 z-auto"
            }
            style={{ display: index === currentVideoIndex ? "block" : "none" }}
            preload="auto"
            loop={shouldLoop} // Loop se è un singolo video
            onCanPlayThrough={() =>
              console.log(`Video pronto a essere riprodotto, index: ${index}`)
            }
            onError={(e) =>
              console.error(`Errore nel video index ${index}: `, e)
            }
          />
        ))}
    </>
  );
};

export default VideoPlayer;
